<template>
    <WidgetWrapper :title="widget.title"
                   :maxHeight="widget.maxHeight"
                   :refreshable="settings.refreshable"
                   :useDateFilter="settings.useDateFilter"
                   @refresh="getData()">
        <template v-slot:body>

            <Loader v-if="isLoading" :text="'Laddar...'" />
            <p v-else-if="errorMessage" class="text-danger">{{errorMessage}}</p>
            <div v-if="!isLoading">
                <pie-chart :chart-data="data" :chart-options="settings.options" class="chart-container" v-if="settings.fields[0].type==='pie'"></pie-chart>
                <mixed-chart :chart-data="data" :chart-options="settings.options" class="chart-container" v-else></mixed-chart>
            </div>
        </template>
    </WidgetWrapper>
</template>

<script>
    import { get } from '@/helpers/api';
    import Loader from "@/components/Loader";
    import WidgetWrapper from "@/components/widgets/WidgetWrapper";
    import { Bar, Line, Pie } from 'vue-chartjs';
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, LineElement, PointElement, ArcElement, CategoryScale, LinearScale, BarController, LineController, PieController } from 'chart.js';

    ChartJS.register(Title, Tooltip, Legend, BarElement, LineElement, PointElement, ArcElement, CategoryScale, LinearScale, BarController, LineController, PieController);


    export default {
        name: 'ChartWidget',
        components: { Loader, WidgetWrapper, 'mixed-chart': Bar, 'pie-chart': Pie },
        props: ["widget", "customerId", "startDate", "endDate"],
        data() {
            return {
                isLoading: true, 
                mainType: 'Bar',
                data: {
                    labels: [],
                    datasets: []
                },
                chartData: {
                    labels: ['January', 'February', 'March'],
                    datasets: [
                        {
                            label: 'Data One',
                            backgroundColor: '#f87979',
                            data: [40, 20, 12]
                        }
                    ]
                }, chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                settings: {
                    trackRowBy: "id",
                    clickableRows: false,
                    refreshable: false,
                    useDateFilter: false,
                    options: {},
                    fields: [],
                },
                errorMessage: '',
            };
        },
        mounted() {
            this.getData(false);

            if (this.widget.RefreshInterval > 0)
                this.interval = setInterval(() => {
                    this.getData(true);
                }, 1000 * this.widget.RefreshInterval);
        },
        methods: {
  
            async getData(silentReload) {
                if (!silentReload) this.isLoading = true;

                this.errorMessage = "";

                await get('Dashboard', `GetWidget/${this.widget.id}?customerId=${this.customerId}&start=${this.startDate}&end=${this.endDate}`)
                    .then((x) => {
                        this.settings = JSON.parse(x.data.settings);

                        if (!this.settings) {
                            this.errorMessage = "Fel vid inl�sning av inst�llningar";
                            return;
                        }

                        if (!x.data.data.length) {
                            //this.errorMessage = "Ingen data att visa.";
                            this.isLoading = false;
                            return;
                        }

                        // Map labels from data
                        this.data.labels = x.data.data.map(item => item[this.settings.label || 'Name']);

                        // Prepare datasets with logic for useDataColor
                        this.data.datasets = this.settings.fields.map((field, index) => {
                            const isPie = field.type === 'pie';
                            const useDataColorField = this.settings.useDataColor;

                            const dataColors = x.data.data.map((item, colorIndex) => {
                                const colorValue = useDataColorField ? item[useDataColorField] : null;
                                return colorValue || field.color[colorIndex % field.color.length];
                            });

                            return {
                                label: field.label,
                                type: field.type,
                                backgroundColor: isPie ? dataColors : this.transparentize(field.color),
                                borderColor: isPie ? '#fff' : field.color,
                                fill: false,
                                data: x.data.data.map(item => item[field.key]),
                                borderWidth: 2
                            };
                        });

                        this.isLoading = false;
                    })
                    .catch((x) => {
                        this.errorMessage = x.message;
                        this.isLoading = false;
                    });
            },



            transparentize(value, opacity) {
                var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                return this.hexToRgba(value, alpha);
            },
            hexToRgba(hex, alpha) {
                let r = parseInt(hex.slice(1, 3), 16);
                let g = parseInt(hex.slice(3, 5), 16);
                let b = parseInt(hex.slice(5, 7), 16);

                return `rgba(${r}, ${g}, ${b}, ${alpha})`;
            }
      
        },
        computed: {
        
        },
        watch: {
            startDate(newVal, oldVal) {
                if (this.settings.useDateFilter)
                    this.getData(true);
            },
            endDate(newVal, oldVal) {
                if (this.settings.useDateFilter)
                    this.getData(true);
            }
        }
    };
</script>

<style scoped>
    .chart-container {
        height: 400px;
        width: 100%; 
    }
</style>